import React, { lazy } from 'react';
import { Router } from './ui/routing/Router';

const appRoutes = [
  { path: '/signin', component: lazy(() => import('./components/SignInPage')) },
  { path: '/error', component: lazy(() => import('./components/ErrorPage')) },
  { path: '/home', component: lazy(() => import('./components/Home')) },
  { path: '/dashboard', isAuthRoute: true, component: lazy(() => import('./components/Dashboard')) },
  { path: '/accessdenied', component: lazy(() => import('./components/AccessDenied')) },
  { path: '*', component: lazy(() => import('./components/SignInPage')) },
];

const AppRoutes = () => {
  return <Router appRoutes={appRoutes} />;
};

export default AppRoutes;