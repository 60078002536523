import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import AppRoutes from './AppRoutes';
import AppTheme from './AppTheme';
import { NavBar, Footer, MainLayout, HeaderSection, MainSection, FooterSection } from './ui';
import { callMsGraph } from './graph';
import './App.css';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!inProgress && isAuthenticated) {
      console.log("User is authenticated");
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log("Token acquired", response);
        if(response?.idTokenClaims?.roles?.length >= 1) {
          callMsGraph(response.accessToken).then((response) => {
            console.log("Graph API response", response);
            const userEmail = response?.userPrincipalName?.toLowerCase();
            setUser({
              displayName: response?.displayName,
              email: userEmail
            });
            console.log("User is allowed", userEmail);
          }).catch(error => {
            console.error("Error calling Graph API", error);
          });
        } else {
          console.log("User is not allowed");
          navigate('/accessdenied');
        }
      }).catch(error => {
        console.error("Error acquiring token", error);
      });
    } else {
      console.log("User is not authenticated");
    }
  }, [isAuthenticated, inProgress]);

  return (
    <AppTheme>
      <MainLayout>
        <HeaderSection style={{ paddingBottom: '8px' }}>
          <NavBar isAuthenticated={isAuthenticated} user={user} />
        </HeaderSection>

        <MainSection>
          <AppRoutes />
        </MainSection>

        <FooterSection>
          <Footer />
        </FooterSection>
      </MainLayout>
    </AppTheme>
  );
}

export default App;