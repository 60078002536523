import React, { useCallback } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const CustomSelect = ({ label, options, setValue, value }) => {

    const onChangeCallback = useCallback((e) => {
        setValue(e?.target?.value);
    }, []);

    return (
        <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id='select-label'>{label}</InputLabel>
            <Select labelId="select-label" label="Environment" onChange={onChangeCallback} value={value} >
                {options.map((option, index) => <MenuItem key={index} value={option.value} selected={value === option.value}>{option.label}</MenuItem> )}
            </Select>
        </FormControl>
    )
}

export { CustomSelect };