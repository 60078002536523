import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000080',
    },
    secondary: {
      main: '#a82308',
    },
  },
  colors: {
    powderWhite: '#FFFDF9',
    persianGreen: '#e5e5e5',
    lightBlue: '#a82308',
    onyx: '#36313D',
    primaryColor: '#e5e5e5',
    navMenuColor: '#FFFDF9',
    menuLinkColor: '#FFFFFF',
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },

});


const AppTheme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default AppTheme;
