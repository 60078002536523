import React from "react";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Close }from '@mui/icons-material';

const FeedbackMessage = ({ open, setOpen, message }) => {
    const handleClose = (event, reason ) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
    );
    return (
        <Snackbar
            open={open}
            anchorOrigin={{vertical:'top', horizontal:'center'}}
            autoHideDuration={3000}
            onClose={handleClose}
            message={message}
            action={action}
        />
    )
}

export { FeedbackMessage };