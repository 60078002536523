import styled from 'styled-components';
import { HeaderSection } from './HeaderSection';
import { MainSection } from './MainSection';
import { FooterSection } from './FooterSection';

export const MainLayoutContainer = styled.main`
  display: grid;
  min-height: 100vh;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  ${HeaderSection} {
    grid-area: header;

    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  ${MainSection} {
    grid-area: main;
    display:flex;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  ${FooterSection} {
    grid-area: footer;

    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
`;
